* {
  font-family: 'Libre Baskerville', serif!important;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
  margin: 0;
}


/* #logo_text {
  font-family: 'Amatic SC'!important;
  font-size: 1.35rem;
  padding: 2px;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(255,0,0,1) 60%);
  background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(255,0,0,1) 60%);
  background: linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(255,0,0,1) 60%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ff0000",GradientType=1);
} */

iframe {
  width: 80vw;
  height: 80vh;
}

/* .pswp {
  z-index: 100!important;
} */

/* Let's get this party started */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(0,0,0,0.5); 
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0,0,0,0.3); 
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px rgba(255,255,255,0.5) inset !important;
}

#onImgText {
  background: rgba(255,255,255,0);
  background: -moz-linear-gradient(90deg, rgba(0,0,0,0.8) 20%, rgba(255,255,255,0) 25%);
  background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.8) 20%, rgba(255,255,255,0) 25%);
  background: linear-gradient(90deg, rgba(0,0,0,0.8) 20%, rgba(255,255,255,0) 25%);
  background-origin: border-box;
}

#bgImg {
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

/* #footer_flags { 
  background: rgb(255,255,255);
  background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 25%, rgba(255,0,0,1) 25%, rgba(255,0,0,1) 50%, rgba(0,0,0,1) 50%, rgba(0,91,188,1) 51%, rgba(0,91,188,1) 75%, rgba(255,214,0,1) 75%);
  background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 25%, rgba(255,0,0,1) 25%, rgba(255,0,0,1) 50%, rgba(0,0,0,1) 50%, rgba(0,91,188,1) 51%, rgba(0,91,188,1) 75%, rgba(255,214,0,1) 75%);
  background: linear-gradient(180deg, rgba(255,255,255,1) 25%, rgba(255,0,0,1) 25%, rgba(255,0,0,1) 50%, rgba(0,0,0,1) 50%, rgba(0,91,188,1) 51%, rgba(0,91,188,1) 75%, rgba(255,214,0,1) 75%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffd600",GradientType=1);
} */

.aws-btn {
  --slider-height-percentage: 50%;
  --slider-transition-duration: 200ms;
  --organic-arrow-thickness: 10px;
  --organic-arrow-border-radius: 10px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #ff0000;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #000000;
  --control-bullet-active-color: #ff0000;
  --loader-bar-color: #ff0000;
  --loader-bar-height: 6px;
  }